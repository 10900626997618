import React from "react"
import { Trans, withTranslation } from "react-i18next"

import LayoutContainer from "../components/layout"

const NotFoundPage = () => (
  <LayoutContainer seoTitle="Page not found">
    <div className="center-align">
      <h1 className="big-title uppercase">
        {" "}
        <Trans i18nKey="404.title" />
      </h1>
      <p className="text">
        <Trans i18nKey="404.text" />
      </p>
    </div>
  </LayoutContainer>
)

export default withTranslation()(NotFoundPage)
